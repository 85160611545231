import PencilRulerIcon from "@/assets/icons/pencil-ruler.svg?component";
import HomeIcon from "@/assets/icons/home.svg?component";
import StarIcon from "@/assets/icons/star.svg?component";
import RelaxIcon from "@/assets/icons/relax.svg?component";
import HammerIcon from "@/assets/icons/hammer.svg?component";
import BriefcaseIcon from "@/assets/icons/briefcase.svg?component";

export const usePostTypes = createSharedComposable(() => {
  const { t } = useI18n();

  const postTypeSlugs = computed<Record<PostType, string>>(() => ({
    [PostType.Architect]: t("post.type.architect.slug"),
    [PostType.Inspiration]: t("post.type.inspiration.slug"),
    [PostType.Professional]: t("post.type.professional.slug"),
    [PostType.Relaxation]: t("post.type.relaxation.slug"),
    [PostType.Contractor]: t("post.type.contractor.slug"),
    [PostType.Project]: t("post.type.project.slug"),
  }));

  const getPostTypeSlug = (postType: MaybeRef<PostType>) =>
    postTypeSlugs.value[unref(postType)];

  const getPostTypeFromSlug = (slug: MaybeRef<string>) =>
    Object.entries(postTypeSlugs.value).find(
      ([_, postTypeSlug]) => postTypeSlug === unref(slug)
    )?.[0] as PostType | undefined;

  const postTypeTitlesSingular = computed<Record<PostType, string>>(() => ({
    [PostType.Architect]: t("post.type.architect.label.singular"),
    [PostType.Inspiration]: t("post.type.inspiration.label.singular"),
    [PostType.Professional]: t("post.type.professional.label.singular"),
    [PostType.Relaxation]: t("post.type.relaxation.label.singular"),
    [PostType.Contractor]: t("post.type.contractor.label.singular"),
    [PostType.Project]: t("post.type.project.label.singular"),
  }));

  const getPostTypeTitleSingular = (postType: MaybeRef<PostType>) =>
    postTypeTitlesSingular.value[unref(postType)];

  const postTypeTitlesPlural = computed<Record<PostType, string>>(() => ({
    [PostType.Architect]: t("post.type.architect.label.plural"),
    [PostType.Inspiration]: t("post.type.inspiration.label.plural"),
    [PostType.Professional]: t("post.type.professional.label.plural"),
    [PostType.Relaxation]: t("post.type.relaxation.label.plural"),
    [PostType.Contractor]: t("post.type.contractor.label.plural"),
    [PostType.Project]: t("post.type.project.label.plural"),
  }));

  const getPostTypeTitlePlural = (postType: MaybeRef<PostType>) =>
    postTypeTitlesPlural.value[unref(postType)];

  const postTypeIcons = computed<Record<PostType, string>>(() => ({
    [PostType.Architect]: "ns:pencil-ruler",
    [PostType.Inspiration]: "ns:home",
    [PostType.Professional]: "ns:star",
    [PostType.Relaxation]: "ns:relax",
    [PostType.Contractor]: "ns:hammer",
    [PostType.Project]: "ns:briefcase",
  }));

  const postTypeTitlesSeo = computed<Record<PostType, string>>(() => ({
    [PostType.Architect]: t("post.type.architect.name.plural.nominative"),
    [PostType.Inspiration]: t("post.type.inspiration.name.plural.nominative"),
    [PostType.Professional]: t("post.type.professional.name.plural.nominative"),
    [PostType.Relaxation]: t("post.type.relaxation.name.plural.nominative"),
    [PostType.Contractor]: t("post.type.contractor.name.plural.nominative"),
    [PostType.Project]: t("post.type.project.name.plural.nominative"),
  }));

  const getPostTypeTitleSeo = (postType: MaybeRef<PostType>) =>
    postTypeTitlesSeo.value[unref(postType)];

  const getPostTypeIcon = (postType: MaybeRef<PostType>) =>
    postTypeIcons.value[unref(postType)];

  const postTypeIconComponents = computed<Record<PostType, Component>>(() => ({
    [PostType.Architect]: PencilRulerIcon,
    [PostType.Inspiration]: HomeIcon,
    [PostType.Professional]: StarIcon,
    [PostType.Relaxation]: RelaxIcon,
    [PostType.Contractor]: HammerIcon,
    [PostType.Project]: BriefcaseIcon,
  }));

  const getPostTypeIconComponent = (postType: MaybeRef<PostType>) =>
    postTypeIconComponents.value[unref(postType)];

  return {
    postTypeSlugs,
    getPostTypeSlug,
    getPostTypeFromSlug,

    postTypeTitlesSingular,
    getPostTypeTitleSingular,

    postTypeTitlesPlural,
    getPostTypeTitlePlural,

    postTypeTitlesSeo,
    getPostTypeTitleSeo,

    postTypeIcons,
    getPostTypeIcon,

    postTypeIconComponents,
    getPostTypeIconComponent,
  };
});
