export const useTheme = () => {
  const { theme }: any = useTailwindConfig();

  const palette = {
    blue: {
      name: "blue",
      raw: theme.colors.blue?.["DEFAULT"],

      bg: "bg-blue",
      text: "text-blue",
      border: "border-blue",
      accent: "accent-blue",
      scrollbar: "scrollbar-blue",
      selection: "selection-blue",
    },

    rose: {
      name: "rose",
      raw: theme.colors.rose?.["DEFAULT"],

      bg: "bg-rose",
      text: "text-rose",
      border: "border-rose",
      accent: "accent-rose",
      scrollbar: "scrollbar-rose",
      selection: "selection-rose",
    },

    green: {
      name: "green",
      raw: theme.colors.green?.["DEFAULT"],

      bg: "bg-green",
      text: "text-green",
      border: "border-green",
      accent: "accent-green",
      scrollbar: "scrollbar-green",
      selection: "selection-green",
    },

    yellow: {
      name: "yellow",
      raw: theme.colors.yellow?.["DEFAULT"],

      bg: "bg-yellow",
      text: "text-yellow",
      border: "border-yellow",
      accent: "accent-yellow",
      scrollbar: "scrollbar-yellow",
      selection: "selection-yellow",
    },

    darkblue: {
      name: "darkblue",
      raw: theme.colors.darkblue?.["DEFAULT"],

      bg: "bg-darkblue",
      text: "text-darkblue",
      border: "border-darkblue",
      accent: "accent-darkblue",
      scrollbar: "scrollbar-darkblue",
      selection: "selection-darkblue",
    },

    grey: {
      name: "grey",
      raw: theme.colors.grey?.["DEFAULT"],

      bg: "bg-grey",
      text: "text-grey",
      border: "border-grey",
      accent: "accent-grey",
      scrollbar: "scrollbar-grey",
      selection: "selection-grey",
    },

    copperfield: {
      name: "copperfield",
      raw: theme.colors.copperfield?.["DEFAULT"],

      bg: "bg-copperfield",
      text: "text-copperfield",
      border: "border-copperfield",
      accent: "accent-copperfield",
      scrollbar: "scrollbar-copperfield",
      selection: "selection-copperfield",
    },

    mongoose: {
      name: "mongoose",
      raw: theme.colors.mongoose?.["DEFAULT"],

      bg: "bg-mongoose",
      text: "text-mongoose",
      border: "border-mongoose",
      accent: "accent-mongoose",
      scrollbar: "scrollbar-mongoose",
      selection: "selection-mongoose",
    },
  };

  const getPaletteForPostType = (type?: PostType) => {
    return type
      ? {
          [PostType.Inspiration]: palette.rose,
          [PostType.Professional]: palette.yellow,
          [PostType.Architect]: palette.darkblue,
          [PostType.Relaxation]: palette.green,
          [PostType.Contractor]: palette.mongoose,
          [PostType.Project]: palette.copperfield,
        }[type]
      : palette.blue;
  };

  const route = useRoute();

  const { getPostTypeSlug } = usePostTypes();

  const currentPalette = computed(() => {
    for (const type of Object.values(PostType)) {
      const slug = getPostTypeSlug(type);
      if (slug && route.path.startsWith(`/${slug}`)) {
        return getPaletteForPostType(type);
      }
    }

    return palette.blue;
  });

  return {
    currentPalette,
    getPaletteForPostType,
  };
};
