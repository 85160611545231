export const useUserDevice = createSharedComposable(() => {
  const viewportWidth = useRequestHeader("Viewport-Width");
  const viewportHeight = useRequestHeader("Viewport-Height");

  const { isDesktop: isDesktopDevice, isCrawler } = useDevice();

  const { width, height } = useWindowSize({
    initialWidth: viewportWidth
      ? parseInt(viewportWidth, 10)
      : isDesktopDevice
      ? 1440
      : 390,
    initialHeight: viewportHeight
      ? parseInt(viewportHeight, 10)
      : isDesktopDevice
      ? 900
      : 640,
    listenOrientation: true,
  });

  const isMobile = computed(() => width.value < 1024);
  const isDesktop = computed(() => !isMobile.value);

  const { sourceType } = useMouse();
  const isTouch = useState("is-touch-device", () => false);

  watch(sourceType, (type, prev) => {
    isTouch.value = type === "touch" || prev === "touch";
  });

  const vmin = computed(() => Math.min(width.value, height.value));

  const remFactor = computed(() =>
    isMobile.value ? vmin.value / 390 : Math.min(width.value, 1920) / 1920
  );

  const baseFontSize = computed(() => 16 * remFactor.value);

  const px = computed(() => baseFontSize.value / 16);

  const usePxValue = (v: number) => computed(() => v * px.value);
  const useRemValue = (v: number) => computed(() => v * px.value * 16);

  return {
    isCrawler,

    viewportWidth,
    width,
    height,
    isMobile,
    isDesktop,

    sourceType,
    isTouch,

    remFactor,
    px,
    usePxValue,
    useRemValue,
  };
});
