import { useScriptGoogleAnalytics, useScriptGoogleTagManager, useScript, defineNuxtPlugin } from '#imports'

export default defineNuxtPlugin({
  name: "scripts:init",
  env: { islands: false },
  parallel: true,
  setup() {
    const googleTagManager = useScriptGoogleTagManager({"id":"GTM-PDTWG9CX"})
    const googleAnalytics = useScriptGoogleAnalytics({"id":"G-2Y2T2BHDNL"})
    return { provide: { $scripts: { googleTagManager, googleAnalytics } } }
  }
})