import type { SimpleGeometry, User } from "@directus/types";
import type { ArgumentsType } from "@vueuse/core";

export enum PostType {
  Inspiration = "inspiration",
  Professional = "professional",
  Architect = "architect",
  Relaxation = "relaxation",
  Contractor = "contractor",
  Project = "project",
}

export interface FileItem extends Record<string, any> {
  id?: string;
  description?: string;
  width?: number;
  height?: number;
  type?: string;
}

export interface ImageItem extends Record<string, any> {
  id?: string;
  file?: FileItem;
  caption?: string;
}

export interface VideoItem extends Record<string, any> {
  id?: string;
  file?: FileItem;
  caption?: string;
  poster?: FileItem;
}

export interface PostImageItem extends Record<string, any> {
  id?: string;
  sort?: number;
  images_id?: ImageItem;
  posts_id?: PostItem;
}

export interface PostVideoItem extends Record<string, any> {
  id?: string;
  sort?: number;
  videos_id?: VideoItem;
  posts_id?: PostItem;
}

export interface TextContentItem extends Record<string, any> {
  id?: string;
  value?: string;
}

export interface TitleContentItem extends Record<string, any> {
  id?: string;
  level?: "h2" | "h3";
  type?: "normal" | "decorative" | "mixed";
  value_normal?: string;
  value_decorative?: string;
}

export interface BreakContentItem extends Record<string, any> {
  id?: string;
}

export interface PostTagItem extends Record<string, any> {
  id?: string;
  sort?: number;
  tags_id?: TagItem;
}

export interface TagItem extends Record<string, any> {
  id?: string;
  title?: string;
  filters?: PostType[];
}

export interface PostContentItemBase extends Record<string, any> {
  id?: string;
  post_id?: PostItem;
  sort?: number;
  // dynamic
  collection?: string;
  item?: any;
}

export interface PostContentImageItem extends PostContentItemBase {
  collection: "images";
  item?: ImageItem;
}

export interface PostContentVideoeItem extends PostContentItemBase {
  collection: "videos";
  item?: VideoItem;
}

export interface PostContentTextItem extends PostContentItemBase {
  collection: "content_text";
  item?: TextContentItem;
}

export interface PostContentTitleItem extends PostContentItemBase {
  collection: "content_title";
  item?: TitleContentItem;
}

export interface PostContentBreakItem extends PostContentItemBase {
  collection: "content_break";
  item?: BreakContentItem;
}

export type PostContentItem =
  | PostContentTextItem
  | PostContentImageItem
  | PostContentVideoeItem
  | PostContentTitleItem
  | PostContentBreakItem;

export interface AuthorItem extends Record<string, any> {
  id?: string;
  image?: FileItem;
  name?: string;
  role?: string;
  description?: string;
  // posts?: PostItem[];
}

export interface PostRelatedItem extends Record<string, any> {
  id?: string;
  posts_id?: PostItem;
  related_posts_id?: PostItem;
  sort?: number;
}

export interface PostItem extends Record<string, any> {
  id?: string;

  status?: "published" | "draft" | "archived";
  date_published?: string;

  type?: PostType;
  title?: string;
  slug?: string;
  subtitle_type?: "subtitle" | "architects";
  subtitle?: string;
  subtitle_label?: string;

  logo?: FileItem;
  parent_post?: PostItem | null;

  tags?: PostTagItem[];
  cover?: ImageItem;

  architect_name?: string;
  architect_link?: string;

  architects?: {
    name?: string;
    link?: string;
  }[];

  photographer_name?: string;
  photographer_link?: string;

  photographers?: {
    name?: string;
    link?: string;
  }[];

  website_name?: string;
  website_link?: string;

  social_links?: {
    name?: string;
    link?: string;
  }[];

  location_address?: string;

  project_details_enabled?: boolean;

  project_details_title_normal?: string;
  project_details_title_decorative?: string;

  project_details_price_value?: string;
  project_details_price_currency?: string;

  project_details_area_data?: {
    type: "building_area" | "usable_area" | "pitch_angle";
    value: number;
  }[];

  project_details_specification_value?: string;

  content?: PostContentItem[];

  cta_enabled?: boolean;
  cta_title_normal?: string;
  cta_title_decorative?: string;
  cta_content?: string;
  cta_actions?: {
    type: "phone" | "email" | "location";
    value: string;
  }[];

  images?: PostImageItem[];
  videos?: PostVideoItem[];

  location_map_enabled?: boolean;
  location_map_marker?: SimpleGeometry;

  author?: AuthorItem;
  related?: PostRelatedItem[];

  meta_title?: string;
  meta_description?: string;
}

export interface BoardImageItem extends Record<string, any> {
  id?: string;
  boards_id?: BoardItem;
  images_id?: ImageItem;
  posts_id?: PostItem;
}

export interface BoardItem extends Record<string, any> {
  id?: string;
  title?: string;
  owner?: { id?: string };
  items?: BoardImageItem[];
}

export interface ContactMessage extends Record<string, any> {
  name?: string;
  email?: string;
  message?: string;
  consent?: boolean;
}

export interface CtaContentItem extends Record<string, any> {
  id?: string;
  page?: PageType;
  pre_title?: string;
  title?: string;
  content?: string;
  action_prompt?: string;
  action_button?: string;
}

export interface SliderItem extends Record<string, any> {
  id?: string;
  sort?: number;
  image?: FileItem;
  title?: string;
  short_title?: string;
  link?: string;
  pages?: PageType[];
  color?: "blue" | "rose" | "green" | "yellow" | "darkblue" | "grey";
}

export interface FooterContentItem extends Record<string, any> {
  id?: string;
  page?: PageType;
  pre_title?: string;
  title_normal?: string;
  title_decorative?: string;
  content?: string;
}

export interface Settings extends Record<string, any> {
  title?: string;
  description?: string;
}

export interface ViewsSettings extends Record<string, any> {
  auth_login_image?: FileItem;
  auth_recover_image?: FileItem;
  auth_reset_image?: FileItem;
  auth_confirm_image?: FileItem;
  auth_register_image?: FileItem;

  page_contact_title?: string;
  page_contact_description?: string;
  page_contact_image?: FileItem;
  page_contact_image_success?: FileItem;
}

export interface RedirectItem extends Record<string, any> {
  id?: string;
  slug?: string;
  type?: "post";
  post?: PostItem;
  status?: "published" | "draft" | "archived";
}

export interface DirectusSchema {
  posts: PostItem[];

  posts_tags: PostTagItem[];
  tags: TagItem[];

  related_posts: PostRelatedItem[];

  posts_content: PostContentItem[];

  content_text: TextContentItem[];
  content_title: TitleContentItem[];
  content_break: BreakContentItem[];

  posts_images: PostImageItem[];
  posts_videos: PostVideoItem[];

  images: ImageItem[];
  videos: VideoItem[];

  authors: AuthorItem[];

  boards: BoardItem[];
  boards_images: BoardImageItem[];

  contact_messages: ContactMessage[];

  cta: CtaContentItem[];
  slider: SliderItem[];
  footers: FooterContentItem[];
  views_settings: ViewsSettings;
  settings: Settings;

  directus_users: (User & {
    general_consents?: string[];
    marketing_consents?: string[];
  })[];

  documents_terms: { content: string };
  documents_privacy: { content: string };

  redirects: RedirectItem[];
}

export const useCms = () => {
  const options: ArgumentsType<typeof useDirectusRest>[0] = {
    staticToken: false,
    authConfig: {
      credentials: "include",
      autoRefresh: false,
    },
    restConfig: {
      credentials: "include",
    },
  };

  const useGraphql = () => useDirectusGraphql<DirectusSchema>(options);
  const useRest = () => useDirectusRest<DirectusSchema>(options);
  const useItems = () => useDirectusItems<DirectusSchema>(options);
  const useAuth = () => useDirectusAuth<DirectusSchema>(options);
  const useFiles = () => useDirectusFiles<DirectusSchema>(options);
  const useUsers = () => useDirectusUsers<DirectusSchema>(options);

  return { useGraphql, useRest, useItems, useAuth, useFiles, useUsers };
};
