<script setup lang="ts">
const slots = useSlots();

const props = defineProps<{
  prepend?: string;
  default?: string;
  append?: string;

  mobile?: string;

  nowrap?: boolean;

  hidden?: string;
}>();

const showPrepend = computed(() => slots.prepend || props.prepend);
const showDefault = computed(() => slots.default || props.default);
const showAppend = computed(() => slots.append || props.append);

const showMobile = computed(() => slots.mobile || props.mobile);

const { isMobile } = useUserDevice();

const mounted = ref(false);

onMounted(() => {
  mounted.value = true;
});
</script>

<template>
  <h1
    class="page-title text-[1.75rem] lg:text-2xl leading-[1.5rem] lg:text-[3.125rem] lg:leading-[5rem] text-end translate-x-1 text-balance"
    :class="{
      'whitespace-nowrap text-ellipsis overflow-hidden lg:h-20': nowrap,
    }"
    v-bind="$attrs"
  >
    <span
      v-if="showMobile && (isMobile || !mounted)"
      class="font-display tracking-[0.25rem]"
    >
      <slot name="mobile" v-bind="{ props }">
        {{ props.mobile }}
      </slot>
    </span>

    <template v-else>
      <span v-if="showPrepend" class="inline font-display tracking-[0.3125rem]">
        <slot name="prepend" v-bind="{ props }">
          {{ props.prepend }}
        </slot>
      </span>
      <span v-if="showPrepend && showDefault" class="tracking-[0.25rem]"
        >&emsp14;</span
      >
      <span v-if="$slots.default || $props.default" class="inline italic">
        <slot name="default" v-bind="{ props }">
          {{ props.default }}
        </slot>
      </span>
      <span v-if="showDefault && showAppend" class="tracking-[0.25rem]"
        >&emsp14;</span
      >
      <span v-if="showAppend" class="inline font-display tracking-[0.3125rem]">
        <slot name="append" v-bind="{ props }">
          {{ props.append }}
        </slot>
      </span>
    </template>

    <span v-if="hidden || $slots['hidden']" class="sr-only">
      <slot name="hidden" v-bind="{ props }">
        {{ props.hidden }}
      </slot>
    </span>
  </h1>
</template>
