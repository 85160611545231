import type { RouteLocationRaw } from "#vue-router";

export interface MenuItem {
  key: string;
  label: string;
  to: string;
  class?: string;
  activeClass?: string;
}

export const useMenu = createSharedComposable(() => {
  const localePath = useLocalePath();
  const { getPostTypeSlug } = usePostTypes();

  const isMobileMenuOpen = useState("isMobileMenuOpen", () => false);

  const menuItems = computed<MenuItem[]>(() => [
    {
      key: "inspirations",
      label: "inspiracje",
      to: localePath({
        name: "type",
        params: { type: getPostTypeSlug(PostType.Inspiration) },
      }),
      class: "hover:bg-rose",
      activeClass: "bg-rose",
    },
    {
      key: "professionals",
      label: "partnerzy",
      to: localePath({
        name: "type",
        params: { type: getPostTypeSlug(PostType.Professional) },
      }),
      class: "hover:bg-yellow",
      activeClass: "bg-yellow",
    },
    {
      key: "architects",
      label: "architekci",
      to: localePath({
        name: "type",
        params: { type: getPostTypeSlug(PostType.Architect) },
      }),
      class: "hover:bg-darkblue",
      activeClass: "bg-darkblue",
    },
    {
      key: "relaxation",
      label: "relaks",
      to: localePath({
        name: "type",
        params: { type: getPostTypeSlug(PostType.Relaxation) },
      }),
      class: "hover:bg-green",
      activeClass: "bg-green",
    },
    {
      key: "contractors",
      label: "wykonawcy",
      to: localePath({
        name: "type",
        params: { type: getPostTypeSlug(PostType.Contractor) },
      }),
      class: "hover:bg-mongoose",
      activeClass: "bg-mongoose",
    },
    {
      key: "projects",
      label: "gotowe projekty",
      to: localePath({
        name: "type",
        params: { type: getPostTypeSlug(PostType.Project) },
      }),
      class: "hover:bg-copperfield",
      activeClass: "bg-copperfield",
    },
  ]);

  return { isMobileMenuOpen, menuItems };
});
