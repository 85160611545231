<template>
  <section class="page-header py-5 lg:py-0 min-h-16 lg:min-h-20 flex flex-col items-stretch justify-center select-none">
    <div class="page-header__container container">
      <div class="page-header__wrapper flex items-center justify-between lg:gap-12">
        <div class="page-header__side page-header__side--start flex items-center gap-6">
          <slot name="start"></slot>
        </div>
        <div class="page-header__side page-header__side--end flex items-center gap-6 min-w-0">
          <slot name="end"></slot>
        </div>
      </div>
    </div>
  </section>
</template>
