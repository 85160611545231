<script setup lang="ts">
import { NuxtLink } from "#components";

const emit = defineEmits<{
  click: [event: MouseEvent];
}>();

const attrs = useAttrs();

const props = withDefaults(
  defineProps<{
    type?: "button" | "submit" | "reset" | "link" | "nuxt-link";
    variant?:
      | "outline"
      | "solid"
      | "text"
      | "danger-outline"
      | "danger-solid"
      | "danger-text"
      | "social";
    size?: "xs" | "sm" | "md" | "lg";
    block?: boolean;
    icon?: string | boolean;
  }>(),
  {
    type: "button",
    variant: "outline",
    size: "md",
    block: false,
  }
);

const is = computed(() => {
  switch (props.type) {
    case "button":
    case "submit":
    case "reset":
      return "button";
    case "nuxt-link":
      return NuxtLink;
    case "link":
      return "a";
    default:
      if (attrs.to) {
        return NuxtLink;
      } else if (attrs.href) {
        return "a";
      } else {
        return "button";
      }
  }
});

const type = computed(() => {
  if (["button", "submit", "reset"].includes(props.type)) {
    return props.type;
  } else {
    return undefined;
  }
});

const classList = computed(() => [
  "app-btn",
  `app-btn--${props.variant}`,
  `app-btn--${props.size}`,
  `app-btn--${props.type}`,

  {
    "app-btn--block flex w-full": props.block,
    "app-btn-inline inline-flex w-auto": !props.block,
  },

  "items-center justify-center gap-2",
  "border-[0.0625rem] rounded-full",
  "transition select-none",
  "overflow-hidden whitespace-nowrap text-ellipsis",
  "min-w-0 max-w-full [&>*]:min-w-0",

  ...(props.icon
    ? [
        "app-btn--icon px-3",
        {
          "!w-6": props.size === "xs",
          "!w-8": props.size === "sm",
          "!w-12": props.size === "md",
          "!w-14": props.size === "lg",
        },
      ]
    : ["px-12"]),

  {
    "app-btn--disabled cursor-not-allowed bg-opacity-30 border-opacity-30 text-opacity-30":
      attrs.disabled,

    "border-black bg-transparent text-black hover:border-black hover:bg-black hover:text-white":
      props.variant === "outline",
    "border-black bg-black text-white hover:border-white hover:bg-white hover:text-black":
      props.variant === "solid",
    "border-transparent bg-transparent text-black hover:border-black hover:bg-transparent hover:text-black":
      props.variant === "text",

    "border-danger bg-transparent text-danger hover:border-danger hover:bg-danger hover:text-white":
      props.variant === "danger-outline",
    "border-danger bg-danger text-white hover:border-white hover:bg-white hover:text-danger":
      props.variant === "danger-solid",
    "border-transparent bg-transparent text-danger hover:border-danger hover:bg-transparent hover:text-danger":
      props.variant === "danger-text",

    "border-grey-100 bg-grey-100 text-black hover:bg-black hover:border-black hover:text-white":
      props.variant === "social",

    "!h-6 text-base": props.size === "xs",
    "!h-8 text-sm": props.size === "sm",
    "!h-12 text-base": props.size === "md",
    "!h-14 text-lg": props.size === "lg",
  },
]);

const onClick = (event: MouseEvent) => {
  if (attrs.disabled) {
    event.preventDefault();
    return;
  }

  emit("click", event);
};
</script>

<template>
  <component
    :is="is"
    :type="type"
    :class="classList"
    v-bind="attrs"
    @click="onClick"
  >
    <slot></slot>
  </component>
</template>
