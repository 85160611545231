import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "25",
  height: "25",
  fill: "none"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      stroke: "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      d: "m15.5 5.5 4 4M13.5 7.5 9.2 3.2a2.41 2.41 0 0 0-3.4 0L3.2 5.8a2.41 2.41 0 0 0 0 3.4l4.3 4.3M8.5 6.5l2-2M2.5 22.5 8 21 21.67 7.33a2.829 2.829 0 0 0-4-4L4 17zM18.5 16.5l2-2"
    }, null, -1),
    _createElementVNode("path", {
      stroke: "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      d: "m17.5 11.5 4.3 4.3c.94.94.94 2.46 0 3.4l-2.6 2.6c-.94.94-2.46.94-3.4 0l-4.3-4.3"
    }, null, -1)
  ])))
}
export default { render: render }