import type { Serializer } from "@vueuse/core";

type SavePostAfterLogin = {
  post: PostItem;
  image?: ImageItem;
} | null;

const serializer: Serializer<SavePostAfterLogin> = {
  read: (value) => JSON.parse(value),
  write: (value) => JSON.stringify(value),
};

export const usePostSave = () => {
  const savedPost = useState<PostItem>("post-save-item");
  const savedImage = useState<ImageItem | undefined>("post-save-image");
  const isSaveOpen = useState<boolean>("post-save-open", () => false);

  const savePostAfterLogin = useLocalStorage<SavePostAfterLogin>(
    "save-post-after-login",
    null,
    { serializer }
  );

  const savePost = (
    post: MaybeRef<PostItem>,
    image?: MaybeRef<ImageItem | undefined>
  ) => {
    savedPost.value = unref(post);
    savedImage.value = unref(image);
    nextTick(() => {
      isSaveOpen.value = true;
    });
  };

  const closeSave = () => {
    isSaveOpen.value = false;
  };

  return {
    savedPost,
    savedImage,
    isSaveOpen,

    savePostAfterLogin,

    savePost,
    closeSave,
  };
};
