export const useSettings = () => {
  const { useItems } = useCms();
  const { readSingleton } = useItems();

  const getSettings = async () => {
    return await readSingleton("settings");
  };

  return { getSettings };
};
