import { default as _91_46_46_46uri_9328X31qNNOMMeta } from "/src/pages/[...uri].vue?macro=true";
import { default as _91slug_93IELoqQ1TSAMeta } from "/src/pages/[type]/[slug].vue?macro=true";
import { default as indexCJtWNcAPuRMeta } from "/src/pages/[type]/index.vue?macro=true";
import { default as consentsLeThuKoHw9Meta } from "/src/pages/account/consents.vue?macro=true";
import { default as settingsVZPN3xkO33Meta } from "/src/pages/account/settings.vue?macro=true";
import { default as accountqjnKNJeI6iMeta } from "/src/pages/account.vue?macro=true";
import { default as _91id_936KNKlIMqVsMeta } from "/src/pages/boards/[id].vue?macro=true";
import { default as index5MfrMEy3B1Meta } from "/src/pages/boards/index.vue?macro=true";
import { default as indext2d5rnc5eWMeta } from "/src/pages/contact/index.vue?macro=true";
import { default as indexvsa0KwJWqiMeta } from "/src/pages/index.vue?macro=true";
import { default as privacyThqmx5VZe0Meta } from "/src/pages/legal/privacy.vue?macro=true";
import { default as termsgAcgp3eqFdMeta } from "/src/pages/legal/terms.vue?macro=true";
import { default as verify_45emailYTPRwYkxfTMeta } from "/src/pages/verify-email.vue?macro=true";
import { default as verify_45reset5hPxMmix4BMeta } from "/src/pages/verify-reset.vue?macro=true";
import { default as component_45stubcvjfpE8ROHMeta } from "/src/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.7_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_m5adwvvesxiv2hnpjsnekwl2ya/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubcvjfpE8ROH } from "/src/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.7_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_m5adwvvesxiv2hnpjsnekwl2ya/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "uri___pl",
    path: "/:uri(.*)*",
    component: () => import("/src/pages/[...uri].vue")
  },
  {
    name: "type-slug___pl",
    path: "/:type()/:slug()",
    component: () => import("/src/pages/[type]/[slug].vue")
  },
  {
    name: "type___pl",
    path: "/:type()",
    meta: indexCJtWNcAPuRMeta || {},
    component: () => import("/src/pages/[type]/index.vue")
  },
  {
    name: "account___pl",
    path: "/konto",
    component: () => import("/src/pages/account.vue"),
    children: [
  {
    name: "account-consents___pl",
    path: "zgody",
    component: () => import("/src/pages/account/consents.vue")
  },
  {
    name: "account-settings___pl",
    path: "ustawienia",
    component: () => import("/src/pages/account/settings.vue")
  }
]
  },
  {
    name: "boards-id___pl",
    path: "/tablice/:id()",
    component: () => import("/src/pages/boards/[id].vue")
  },
  {
    name: "boards___pl",
    path: "/tablice",
    component: () => import("/src/pages/boards/index.vue")
  },
  {
    name: "contact___pl",
    path: "/kontakt",
    component: () => import("/src/pages/contact/index.vue")
  },
  {
    name: "index___pl",
    path: "/",
    meta: indexvsa0KwJWqiMeta || {},
    component: () => import("/src/pages/index.vue")
  },
  {
    name: "legal-privacy___pl",
    path: "/dokumenty/prywatnosc",
    component: () => import("/src/pages/legal/privacy.vue")
  },
  {
    name: "legal-terms___pl",
    path: "/dokumenty/regulamin",
    component: () => import("/src/pages/legal/terms.vue")
  },
  {
    name: "verify-email___pl",
    path: "/verify-email",
    component: () => import("/src/pages/verify-email.vue")
  },
  {
    name: "verify-reset___pl",
    path: "/verify-reset",
    component: () => import("/src/pages/verify-reset.vue")
  },
  {
    name: component_45stubcvjfpE8ROHMeta?.name,
    path: "/zbuduj-lub-kup/:pathMatch(.*)",
    component: component_45stubcvjfpE8ROH
  },
  {
    name: component_45stubcvjfpE8ROHMeta?.name,
    path: "/mini-domki/:pathMatch(.*)",
    component: component_45stubcvjfpE8ROH
  },
  {
    name: component_45stubcvjfpE8ROHMeta?.name,
    path: "/do-wynajecia/:pathMatch(.*)",
    component: component_45stubcvjfpE8ROH
  },
  {
    name: component_45stubcvjfpE8ROHMeta?.name,
    path: "/materialy/:pathMatch(.*)",
    component: component_45stubcvjfpE8ROH
  },
  {
    name: component_45stubcvjfpE8ROHMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubcvjfpE8ROH
  },
  {
    name: component_45stubcvjfpE8ROHMeta?.name,
    path: "/default-sitemap.xml",
    component: component_45stubcvjfpE8ROH
  },
  {
    name: component_45stubcvjfpE8ROHMeta?.name,
    path: "/types-sitemap.xml",
    component: component_45stubcvjfpE8ROH
  },
  {
    name: component_45stubcvjfpE8ROHMeta?.name,
    path: "/posts-sitemap.xml",
    component: component_45stubcvjfpE8ROH
  }
]