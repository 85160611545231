<script setup lang="ts">
const config = useRuntimeConfig();
const route = useRoute();
const router = useRouter();

const { currentPalette } = useTheme();
provideHeadlessUseId(() => useId());

const { getSettings } = useSettings();

const { data } = await useAsyncData(
  "settings",
  async () => await getSettings(),
  { immediate: true }
);

const title = computed(() => data.value?.title);
const description = computed(() => data.value?.description);

const { titleTemplate } = useSeo(title);

const ogTitle = computed(() => titleTemplate(title.value));

useSeoMeta({ title, ogTitle, titleTemplate, description });

useHead(() => ({
  script: [
    // ...(config.public?.cookieYes?.id
    //   ? [
    //       {
    //         id: "cookieyes",
    //         type: "text/javascript",
    //         src: `https://cdn-cookieyes.com/client_data/${config.public.cookieYes.id}/script.js`,
    //         tagPriority: 999999,
    //         async: true,
    //       },
    //     ]
    //   : []),
  ],
  link: [
    // CookieYes
    {
      rel: "dns-prefetch",
      href: "https://cdn-cookieyes.com",
      crossorigin: "",
    },
    {
      rel: "preconnect",
      href: "https://cdn-cookieyes.com",
    },
    ...(config.public?.cookieYes?.id
      ? [
          {
            rel: "preload",
            href: `https://cdn-cookieyes.com/client_data/${config.public.cookieYes.id}/script.js`,
          },
        ]
      : []),

    // Fonts
    {
      rel: "preload",
      type: "font/otf",
      href: "/fonts/Allusion.otf",
      as: "font",
      crossorigin: "",
    },
    {
      rel: "preload",
      type: "font/woff",
      href: "/fonts/allusion-webfont.woff",
      as: "font",
      crossorigin: "",
    },
    {
      rel: "preload",
      type: "font/woff2",
      href: "/fonts/allusion-webfont.woff2",
      as: "font",
      crossorigin: "",
    },

    // Google
    {
      rel: "dns-prefetch",
      href: "https://www.googletagmanager.com",
      crossorigin: "",
    },
    {
      rel: "dns-prefetch",
      href: "https://maps.googleapis.com",
      crossorigin: "",
    },
    {
      rel: "dns-prefetch",
      href: "https://www.googleadservices.com",
    },
  ],
}));

router.beforeEach((to, from, next) => {
  document.body.setAttribute("tabindex", "-1");
  document.body.focus();
  document.body.removeAttribute("tabindex");
  next();
});

useSchemaOrg([
  defineWebSite({
    "@context": "https://schema.org",
    "@type": "WebSite",
    url: config.public.baseUrl,
    potentialAction: [
      defineSearchAction({
        "@type": "SearchAction",
        target: {
          "@type": "EntryPoint",
          urlTemplate: `${config.public.baseUrl}/?search={search_term_string}`,
        },
        "query-input": "required name=search_term_string",
      }),
    ],
  }),
]);

onErrorCaptured(() => {});

const { isLoggedIn, refreshUser } = useAuth();
const { isIos } = useDevice();

const { isSearchOpen } = useSearch();
onStartTyping(() => (isSearchOpen.value = true));

const { isMobileMenuOpen } = useMenu();

const focused = useWindowFocus();
whenever(focused, () => refreshUser());
router.afterEach(() => refreshUser());
</script>

<template>
  <Html
    class="font-body overflow-x-hidden overscroll-x-none text-[4.102564102vmin] lg:text-[min(16px,_1.111111111vw)]"
    :class="[currentPalette?.scrollbar, currentPalette?.selection]"
  >
    <Head>
      <Title>{{ title }}</Title>

      <Meta charset="utf-8" />

      <Meta name="HandheldFriendly" content="True" />
      <Meta name="MobileOptimized" content="390" />

      <Meta
        name="viewport"
        :content="
          isIos
            ? 'width=device-width, initial-scale=1, maximum-scale=1'
            : 'width=device-width, initial-scale=1'
        "
      />

      <Link rel="icon" type="image/x-icon" href="/favicon.ico" />
      <Link rel="icon" type="image/svg+xml" href="/favicon.svg" />
      <Link rel="icon" type="image/png" href="/favicon.png" />

      <NuxtPwaManifest />
      <NuxtPwaAssets />

      <Meta name="description" :content="description" />
    </Head>

    <Body class="overflow-x-none">
      <NuxtLoadingIndicator :color="currentPalette?.raw" :height="4" />

      <div class="app">
        <slot />
      </div>

      <LazyAppMenu v-model:is-open="isMobileMenuOpen" />

      <LazyAppScroll />
      <LazyAppAuth />
      <LazyPostShare />
      <LazyPostSave v-if="isLoggedIn" />
      <LazyBoardShare />
    </Body>
  </Html>
</template>
