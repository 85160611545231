import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "25",
  height: "25",
  fill: "none"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      stroke: "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      d: "m2.714 13.762 5.883-4.576 5.884 4.576v7.19a1.307 1.307 0 0 1-1.307 1.308H4.02a1.31 1.31 0 0 1-1.307-1.307z"
    }, null, -1),
    _createElementVNode("path", {
      stroke: "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      d: "M17.464 19.037h4.53a1.053 1.053 0 0 0 .737-1.791l-3.16-3.476h.316a1.054 1.054 0 0 0 .737-1.791l-3.16-3.477h.21a1.054 1.054 0 0 0 .843-1.79l-4.213-4.53L9.74 6.94"
    }, null, -1),
    _createElementVNode("path", {
      stroke: "currentColor",
      "stroke-linecap": "round",
      d: "M17.357 19.037v3.223"
    }, null, -1)
  ])))
}
export default { render: render }