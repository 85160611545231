import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "25",
  height: "25",
  fill: "none"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      stroke: "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      d: "M15.5 12.5 7 21a2.118 2.118 0 0 1-3.46-.688A2.12 2.12 0 0 1 4 18l8.5-8.5M18.14 15.5l4.36-4.36"
    }, null, -1),
    _createElementVNode("path", {
      stroke: "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      d: "m21.41 12.2-1.25-1.25c-.6-.6-.93-1.4-.93-2.25v-.86L16.51 5.1a5.56 5.56 0 0 0-3.94-1.64H9.5l.92.82A6.18 6.18 0 0 1 12.5 8.9v1.56l2 2h2.47l2.26 1.91"
    }, null, -1)
  ])))
}
export default { render: render }